import { ErsSiteTheme } from '../models';

/**
 * application setting configuration
 */
export const ersSettings = {
  colorCombinations: [
    { backgroundColor: 'dbeafe', initialsColor: '1e40af' },
    { backgroundColor: 'f3f4f6', initialsColor: '1f2937' },
    { backgroundColor: 'fee2e2', initialsColor: '991b1b' },
    { backgroundColor: 'dcfce7', initialsColor: '166534' },
    { backgroundColor: 'fef9c3', initialsColor: '854d0e' },
    { backgroundColor: 'e0e7ff', initialsColor: '3730a3' },
    { backgroundColor: 'f3e8ff', initialsColor: '6b21a8' },
    { backgroundColor: 'fce7f3', initialsColor: '9d174d' },
  ],
};

export const ersThemes: ErsSiteTheme[] = [
  {
    color: '#ffd9e1',
    displayName: 'Rose & Red',
    name: 'rose-red',
    background: '#fffbff',
    type: 'light',
  },
  {
    color: '#d7e3ff',
    displayName: 'Azure & Blue',
    name: 'azure-blue',
    background: '#fdfbff',
    isDefault: true,
    type: 'light',
  },
];

export interface ColorCombination {
  backgroundColor: string;
  initialsColor: string;
}
