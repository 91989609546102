"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genericObjectTypeFn = exports.objectDifferenceByProps = exports.camelCaseKeysHelper = exports.toCamelCaseKeys = exports.isEmptyInDepth = exports.isNullOrUndefinedEmpty = exports.hasValidLength = exports.isEmpty = exports.remainingDaysHoursFormSeconds = exports.remainingDaysHoursFormTwoDates = exports.daysTimeFromSeconds = exports.objectNonShadowCopy = exports.leadZeroForMonthOrDay = exports.isObject = exports.isDate = exports.isNullOrUndefined = void 0;
const lodash_1 = require("lodash");
/**
 * verifies object is null or undefined, if 'yes' return true.
 * @param value type any
 * @returns boolean
 */
const isNullOrUndefined = (value) => {
    return value === null || value === undefined;
};
exports.isNullOrUndefined = isNullOrUndefined;
/**
 *
 * @param value
 * @returns
 */
const isDate = (value) => {
    try {
        return !isNaN(new Date(value).getTime());
    }
    catch (e) {
        return false;
    }
};
exports.isDate = isDate;
/**
 *
 * @param value
 * @returns
 */
const isObject = (value) => {
    return !(0, exports.isNullOrUndefined)(value) ? typeof value === 'object' : false;
};
exports.isObject = isObject;
/**
 * Add leading '0' to the either to month or day
 * @param value type number
 * @returns number or string
 */
const leadZeroForMonthOrDay = (value) => {
    return value < 10 ? `0${value}` : value;
};
exports.leadZeroForMonthOrDay = leadZeroForMonthOrDay;
/**
 *
 * @param value
 * @returns
 */
const objectNonShadowCopy = (value) => {
    return JSON.parse(JSON.stringify(value));
};
exports.objectNonShadowCopy = objectNonShadowCopy;
/**
 * Calculate number of days, hours, minutes and seconds remaining for given seconds
 * @param value type 'number'
 * @returns an object consists number of days, hours, minutes and seconds remaining
 */
const daysTimeFromSeconds = (seconds) => {
    return {
        days: Math.floor(seconds / 86400),
        hours: Math.floor(seconds / 3600) % 24,
        minutes: Math.floor(seconds / 60) % 60,
        seconds: seconds % 60,
    };
};
exports.daysTimeFromSeconds = daysTimeFromSeconds;
/**
 *
 * @param value
 * @returns
 */
const remainingDaysHoursFormTwoDates = (startDate, finish) => {
    try {
        if (finish.getTime() > startDate.getTime()) {
            return (0, exports.daysTimeFromSeconds)((finish.getTime() - startDate.getTime()) / 1000);
        }
        else {
            return null;
        }
    }
    catch (error) {
        return null;
    }
};
exports.remainingDaysHoursFormTwoDates = remainingDaysHoursFormTwoDates;
/**
 *
 * @param value
 * @returns
 */
const remainingDaysHoursFormSeconds = (seconds) => {
    try {
        return (0, exports.daysTimeFromSeconds)(seconds);
    }
    catch (error) {
        return null;
    }
};
exports.remainingDaysHoursFormSeconds = remainingDaysHoursFormSeconds;
/**
 * verifies object length equals to 0, if 'yes' return true.
 * @param value type any
 * @returns boolean
 */
const isEmpty = (value) => {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
};
exports.isEmpty = isEmpty;
const hasValidLength = (value) => {
    // non-strict comparison is intentional, to check for both `null` and `undefined` values
    return value != null && typeof value.length === 'number';
};
exports.hasValidLength = hasValidLength;
/**
 * verifies object is null or undefined and length equals to 0, if 'yes' return true.
 * @param value type any
 * @returns boolean
 */
const isNullOrUndefinedEmpty = (value) => {
    return (0, exports.isNullOrUndefined)(value) && (0, exports.isEmpty)(value);
};
exports.isNullOrUndefinedEmpty = isNullOrUndefinedEmpty;
/**
 * verifies object is empty & it's props, if 'yes' return true.
 * @param value type any
 * @returns boolean
 */
const isEmptyInDepth = (value) => {
    if ((0, exports.isNullOrUndefined)(value)) {
        return true;
    }
    else {
        let keyLength = 0;
        let emptyValues = 0;
        (0, lodash_1.forIn)(value, (v, key) => {
            keyLength += 1;
            switch (typeof v) {
                case 'boolean':
                    emptyValues += v === false ? 1 : 0;
                    break;
                case 'string':
                    emptyValues += v.length <= 0 ? 1 : 0;
                    break;
                case 'object':
                    if ((0, exports.isNullOrUndefined)(v)) {
                        emptyValues += 1;
                    }
                    else {
                        emptyValues += v.length <= 0 ? 1 : 0;
                    }
                    break;
            }
        });
        return keyLength === emptyValues;
    }
};
exports.isEmptyInDepth = isEmptyInDepth;
/**
 *
 * @param obj
 * @returns camelCase notation object
 */
const toCamelCaseKeys = (obj) => {
    return (0, lodash_1.isArray)(obj)
        ? obj.map((o) => (0, exports.toCamelCaseKeys)(o))
        : (0, exports.camelCaseKeysHelper)(obj);
};
exports.toCamelCaseKeys = toCamelCaseKeys;
const camelCaseKeysHelper = (obj) => {
    const entries = Object.entries(obj);
    const mappedEntries = entries.map(([k, v]) => [
        `${k.slice(0, 1).toLowerCase()}${k.slice(1)}`,
        (0, exports.isObject)(v) ? (0, exports.toCamelCaseKeys)(v) : v,
    ]);
    return Object.fromEntries(mappedEntries);
};
exports.camelCaseKeysHelper = camelCaseKeysHelper;
/** difference between two objects
 * return an array object with differed property its source object
 * and its destination object values respectively
 */
const objectDifferenceByProps = (sourceObject, destinationObject) => {
    const diffProps = [];
    if ((0, exports.isNullOrUndefinedEmpty)(sourceObject) &&
        (0, exports.isNullOrUndefinedEmpty)(destinationObject)) {
        return diffProps;
    }
    for (const prop in sourceObject) {
        if (
        // eslint-disable-next-line no-prototype-builtins
        sourceObject.hasOwnProperty(prop) &&
            // eslint-disable-next-line no-prototype-builtins
            destinationObject.hasOwnProperty(prop)) {
            switch (typeof sourceObject[prop]) {
                case 'object':
                    (0, exports.objectDifferenceByProps)(sourceObject[prop], destinationObject[prop]);
                    break;
                default:
                    if (sourceObject[prop] !== destinationObject[prop]) {
                        diffProps.push({
                            property: prop,
                            sourceValue: sourceObject[prop],
                            destinationValue: destinationObject[prop],
                        });
                    }
                    break;
            }
        }
    }
    return diffProps;
};
exports.objectDifferenceByProps = objectDifferenceByProps;
const genericObjectTypeFn = (key, rValue) => ({ [key]: rValue });
exports.genericObjectTypeFn = genericObjectTypeFn;
