import { valuesIn } from 'lodash-es';

import {
  ApiEndpointConfig,
  PathParams,
  UriEndpointConfig,
} from '../models/api.endpoint';
import { ersApiEndpoints } from './configuration';

export const genGetConstructUrl = (
  key: keyof ApiEndpointConfig,
  values: PathParams[] = []
): string => {
  try {
    const uriObject = evaluateTypeSafeByApiConfigKey(key);
    if (values.length > 0) {
      if (
        compareObjectArraysWithTypeSafe(
          values,
          uriObject?.pathParams as PathParams[]
        )
      ) {
        return `${uriObject.uri}/${values.map((v) => valuesIn(v).join('/'))}`;
      } else {
        throw new Error('supplied & configured path params are not matched!');
      }
    } else {
      return `${uriObject.uri}`;
    }
  } catch (error) {
    console.error(key, JSON.stringify(values), error);
    return '';
  }
};
export const genGetQConstructUrl = (
  key: keyof ApiEndpointConfig,
  values: PathParams[] = []
): string => {
  try {
    const uriObject = evaluateTypeSafeByApiConfigKey(key);
    if (values.length > 0) {
      if (
        compareObjectArraysWithTypeSafe(
          values,
          uriObject?.pathParams as PathParams[]
        )
      ) {
        return `${uriObject.uri}${values.map((v) => valuesIn(v)).join('&')}`;
      } else {
        throw new Error('supplied & configured path params are not matched!');
      }
    } else {
      return `${uriObject.uri}`;
    }
  } catch (error) {
    console.error(key, JSON.stringify(values), error);
    return '';
  }
};

export const genPostConstructUrl = (
  key: keyof ApiEndpointConfig,
  requestType: string
): string => {
  try {
    const uriObject = evaluateTypeSafeByApiConfigKey(key);
    if (uriObject.body === requestType) {
      return `${uriObject.uri}`;
    } else {
      throw new Error(
        'request object & configured object type are not matched'
      );
    }
  } catch (error) {
    console.error(key, requestType, error);
    return '';
  }
};

const evaluateTypeSafeByApiConfigKey = (
  key: keyof ApiEndpointConfig
): UriEndpointConfig => {
  return ersApiEndpoints[key] as UriEndpointConfig;
};

const compareObjectArraysWithTypeSafe = (
  arr1: PathParams[],
  arr2: PathParams[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }

    for (const key in obj1) {
      if (!(key in obj2)) {
        return false;
      }

      if (typeof obj1[key] !== typeof obj2[key]) {
        return false;
      }
      return true;
    }
  }
  return true;
};
