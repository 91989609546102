import { ApiEndpointConfig } from '../models/api.endpoint';

export const ersApiEndpoints: ApiEndpointConfig = {
  fetchTenantsByUsername: {
    uri: 'Tenant/getalltenantsbyusername',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchallSubtenantsByTenant: {
    uri: 'Tenant/getallsubtenantsbytenatid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  createOrganization: {
    uri: 'Tenant/savetenant',
    verb: 'POST',
    body: 'CreateOrganizatonCommand',
    pathParams: [],
  },
  fetchTenantById: {
    uri: 'Tenant/gettenantbyid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  updateOrganization: {
    uri: 'Tenant/updatetenant',
    verb: 'POST',
    body: 'UpdateOrganizatonCommand',
    pathParams: [],
  },
  deleteOrganization: {
    uri: 'Tenant/deletetenant',
    verb: 'POST',
    body: 'DeleteOrganizatonCommand',
    pathParams: [],
  },
  fetchUsersByTenantId: {
    uri: 'User/getallusersbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  deleteUser: {
    uri: 'User/deleteuserbyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  fetchUserById: {
    uri: 'User/getuserbyid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  unassignUserTenant: {
    uri: 'User/unassignuserfromtenantbyid',
    verb: 'POST',
    body: 'AssignUserTenantCommand',
    pathParams: [],
  },
  assignUserToTenant: {
    uri: 'User/assignusertotenant',
    verb: 'POST',
    body: 'AssignUserToTenantCommand',
    pathParams: [],
  },
  isRegistered: {
    uri: 'User/checkifuserisregistered?emailId=',
    verb: 'GET',
    pathParams: [{ email: '' }],
  },
  updateUserRegistration: {
    uri: 'User/updateuserregistration',
    verb: 'POST',
    body: 'UpdateUserRegistrationCommand',
    pathParams: [],
  },
  assignUserRole: {
    uri: 'User/saveuserroles',
    verb: 'POST',
    body: 'AssignUserRoleCommand',
    pathParams: [],
  },
  changeUserStatus: {
    uri: 'User/saveuserroles',
    verb: 'POST',
    body: 'AssignUserRoleCommand',
    pathParams: [],
  },
  saveUserRegistration: {
    uri: 'User/saveuserregistration',
    verb: 'POST',
    body: 'SaveUserRegistrationCommand',
    pathParams: [],
  },

  allModulesSubscriptionsByTenant: {
    uri: 'Access/getallmodulessubscriptionsbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  allModulesSubscriptionStatusByTenant: {
    uri: 'Access/getallmodulescurrentsubscriptionstatusbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveAccessModulesSubscription: {
    uri: 'Access/SaveMainModulesSubscription',
    verb: 'POST',
    pathParams: [],
    body: 'SubscribeModuleAccessCommand',
  },
  fetchUserRoleByUserId: {
    uri: 'Access/rolesbyuserid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchModulesByUserId: {
    uri: 'Access/getmodulesbyuserId',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  deleteRoleById: {
    uri: 'Access/deleterolebyid',
    verb: 'POST',
    body: 'GenericDeleteCommand',
    pathParams: [],
  },
  fetchRolesByTenantId: {
    uri: 'Access/rolesbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveRole: {
    uri: 'Access/saverole',
    verb: 'POST',
    body: 'SaveRoleCommand',
    pathParams: [],
  },
  fetchRolesById: {
    uri: 'Access/rolebyid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  allScreenPermissionsByRoleId: {
    uri: 'Access/getallscreenpermissionsbyroleid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveScreenPermissionForRole: {
    uri: 'Access/savescreenpermissionforrole',
    verb: 'POST',
    body: 'object',
    pathParams: [],
  },
  fetchRoleAuthInfo: {
    uri: 'Access/getroleauthbyuserIdandtenantId',
    verb: 'GET',
    pathParams: [{ userId: '', tenantId: '' }],
  },
  fetchSubscriptionPackages: {
    uri: 'Access/getallsubscriptionpackages',
    verb: 'GET',
    pathParams: [],
  },
  fetchSubscriptionHistoryByIdByTenantId: {
    uri: 'Access/getsubscriptionhistorybymoduleidandtenantid',
    verb: 'GET',
    pathParams: [{ moduleId: '', tenantId: '' }],
  },
  fetchEmploymentTypesByTenantId: {
    uri: 'Employee/getemployementstatusbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveEmployeeJobDetails: {
    uri: 'Employee/saveemployeejobdetails',
    verb: 'POST',
    body: 'JobDetails',
    pathParams: [],
  },
  updateEmployeeJobDetails: {
    uri: 'Employee/updateemployeejobdetails',
    verb: 'POST',
    body: 'JobDetails',
    pathParams: [],
  },
  fetchEmpJobDetailByEmpId: {
    uri: 'Employee/getemployeejobdetailsbyemployeeid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  doEmployeeSearch: {
    uri: 'Employee/getpaginatedemployeeslistbysearchfilters',
    verb: 'POST',
    pathParams: [],
    body: 'EmployeeSearchQuery',
  },
  saveEmployeePersonalDetails: {
    uri: 'Employee/saveemployeepersonaldetails',
    verb: 'POST',
    pathParams: [],
    body: 'EmployeePersonalDetails',
  },
  saveEmployeeFileAttachment: {
    uri: 'Employee/saveemployeefileattachment',
    verb: 'POST',
    pathParams: [],
  },
  deleteEmployeePersonalDetailById: {
    uri: 'Employee/deleteemployeepersonaldetails',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  fetchAllLocationsByTenantId: {
    uri: 'Location/getalllocationsbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveLocation: {
    uri: 'Location/savelocation',
    verb: 'POST',
    body: 'LocationCommand',
    pathParams: [],
  },
  deleteLocationById: {
    uri: 'Location/deletelocationbyid',
    verb: 'POST',
    body: 'DeleteLocationCommand',
    pathParams: [],
  },
  getLocationById: {
    uri: 'Location/getlocationbyid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  maritalstatus: {
    uri: 'MasterData/getmaritalstatuslist',
    verb: 'GET',
    pathParams: [],
  },
  countries: { uri: 'MasterData/getcountry', verb: 'GET', pathParams: [] },
  genders: { uri: 'MasterData/getgenderlist', verb: 'GET', pathParams: [] },
  nationality: {
    uri: 'MasterData/getnationalities',
    verb: 'GET',
    pathParams: [],
  },
  dropdowns: {
    uri: 'MasterData/getdropdown',
    verb: 'GET',
    pathParams: [{ type: '' }],
  },
  fetchCurrencies: {
    uri: 'MasterData/getcurrencies',
    verb: 'GET',
    pathParams: [],
  },
  fetchPayGradeByTenantId: {
    uri: 'PayGrade/getpaygradebytenant',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchWorkShiftsByEmployeeid: {
    uri: 'WorkShift/getworkshiftsbyemployeeid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  deletePayGradeById: {
    uri: 'PayGrade/deletepaygradebyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  fetchPayGradeById: {
    uri: 'PayGrade/getpaygradebyid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchPayGradeCurrenciesByPayGradeId: {
    uri: 'PayGrade/getcurrenciesbypaygrade',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  savePayGrade: {
    uri: 'PayGrade/addpaygradebytenant',
    verb: 'POST',
    pathParams: [],
    body: 'PayGradeCommand',
  },
  saveCurrency: {
    uri: 'PayGrade/addcurrencytopaygrade',
    verb: 'POST',
    pathParams: [],
    body: 'CurrencyCommand',
  },
  deleteCurrencyById: {
    uri: 'PayGrade/deletecurrencybyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCurrencyCommand',
  },
  updatePayGradeById: {
    uri: 'PayGrade/updatepaygradebyid',
    verb: 'POST',
    pathParams: [],
    body: 'PayGradeCommand',
  },
  updateCurrencyIntoPayGrade: {
    uri: 'PayGrade/updatecurrencybypaygrade',
    verb: 'POST',
    pathParams: [],
    body: 'CurrencyCommand',
  },
  fetchJobTitleByTenantId: {
    uri: 'JobTitle/getjobtitlesBytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  deleteJobTitle: {
    uri: 'JobTitle/deletejobtitlebyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  fetchJobTitleById: {
    uri: 'JobTitle/getjobtitleByid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  updateJobTitle: {
    uri: 'JobTitle/updatejobtitlebyid',
    verb: 'POST',
    pathParams: [],
    body: 'JobTitleCommand',
  },
  saveJobTitle: {
    uri: 'JobTitle/addjobtitlebytenant',
    verb: 'POST',
    pathParams: [],
    body: 'JobTitleCommand',
  },
  fetchJobCategoryById: {
    uri: 'JobCategory/getJobcategorybyid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchJobCategoryByTenantId: {
    uri: 'JobCategory/getjobcategoryBytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  deleteJobCategoryById: {
    uri: 'JobCategory/deletejobcategorybyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  addCategory: {
    uri: 'JobCategory/addjobcategorybytenant',
    verb: 'POST',
    pathParams: [],
    body: 'SaveCategory',
  },
  updateJobCategory: {
    uri: 'JobCategory/updatejobcategorybyid',
    verb: 'POST',
    pathParams: [],
    body: 'SaveCategory',
  },
  fetchWorkShiftsByTenantId: {
    uri: 'WorkShift/getworkshiftsbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchWorkShiftById: {
    uri: 'WorkShift/getworkshiftbyid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveWorkshift: {
    uri: 'WorkShift/saveworkshift',
    verb: 'POST',
    pathParams: [],
    body: 'SaveWorkShiftCommand',
  },
  deleteWorkshiftById: {
    uri: 'WorkShift/deleteworkshiftbyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  updateWorkshiftById: {
    uri: 'WorkShift/updateworkshiftbyid',
    verb: 'POST',
    pathParams: [],
    body: 'UpdateWorkShiftCommand',
  },
  saveEmployeeGeoTrackingDetails: {
    uri: 'Employee/saveemployeegeotrackingconfig',
    verb: 'POST',
    pathParams: [],
    body: 'SaveEmployeeGeoTrackingConfigCommand',
  },
  fetchEmployeeGeoTrackingByEmployeeId: {
    uri: 'Employee/getemployeegeotrackingconfigbyemployeeid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveAttachment: {
    uri: 'FileManagment/attachdocument',
    verb: 'POST',
    pathParams: [],
    body: 'FormData',
  },
  saveTsAttachment: {
    uri: 'TimeManagmentAttachment/savetsattchment',
    verb: 'POST',
    pathParams: [],
    body: 'TimeSheetAttachmentCommand',
  },
  fetchEmployeeContactDetailsByEmpid: {
    uri: 'Employee/getemployeecontactdetailsbyempid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchAttachmentByTimesheetid: {
    uri: 'TimeManagmentAttachment/getalltsattachmentsbytimesheetid',
    verb: 'GET',
    pathParams: [{ timesheetId: '' }],
    body:'TimeSheetAttachmentQuery'
  },
  saveEmployeeContactDetailsByEmpId: {
    uri: 'Employee/saveemployeecontactbyempid',
    verb: 'POST',
    pathParams: [],
    body: 'SaveEmployeeContactDetailsCommand',
  },
  fetchTenantEmployees: {
    uri: 'Employee/getallemployeesbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchSupervisorByEmpId: {
    uri: 'EmployeeSupervisor/getemployeesuperisorsbyemployeeid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveReportingSupervisor: {
    uri: 'EmployeeSupervisor/saveemployeesupervisor',
    verb: 'POST',
    pathParams: [],
    body: 'SupervisorCommand',
  },
  updateReportingSupervisor: {
    uri: 'EmployeeSupervisor/updateemployeesupervisor',
    verb: 'POST',
    pathParams: [],
    body: 'SupervisorCommand',
  },
  deleteReportingSupervisor: {
    uri: 'EmployeeSupervisor/deleteemployeesupervisorbyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },

  deleteAttachmentByid: {
    uri: 'TimeManagmentAttachment/deletetsattachmentbyid',
    verb: 'POST',
    pathParams: [],
    body: 'TimeSheetAttachmentQuery',
  },
  fetchSubordinateByEmpId: {
    uri: 'EmployeeSubOrdinate/getemployeesubordinatesbyemployeeid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveReportingSubordinate: {
    uri: 'EmployeeSubOrdinate/saveemployeesubordinate',
    verb: 'POST',
    pathParams: [],
    body: 'SubordinateCommand',
  },
  updateReportingSubordinate: {
    uri: 'EmployeeSubOrdinate/updateemployeesubordinate',
    verb: 'POST',
    pathParams: [],
    body: 'SubordinateCommand',
  },
  deleteReportingSubordinate: {
    uri: 'EmployeeSubOrdinate/deleteemployeesubordinatebyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  fetchEmployeePersonalByEmpId: {
    uri: 'Employee/getemployeepersonaldetailsbyempid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchAllProjectsByTenantId: {
    uri: 'Projects/getallprojectsbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveProject: {
    uri: 'Projects/saveproject',
    verb: 'POST',
    pathParams: [],
    body: 'ProjectCommand',
  },
  deleteProjectById: {
    uri: 'Projects/deleteProjectById',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  updateProject: {
    uri: 'Projects/updateproject',
    verb: 'POST',
    pathParams: [],
    body: 'ProjectCommand',
  },
  updateEmpPersonalDetails: {
    uri: 'Employee/updateemployeepersonaldetails',
    verb: 'POST',
    body: 'UpdateEmpPersonalDetailsCommand',
    pathParams: [],
  },
  fetchAllProjectsActivitiesByTenantId: {
    uri: 'Projects/getallprojectsactivitiesbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveProjectActivity: {
    uri: 'Projects/saveprojectactivities',
    verb: 'POST',
    body: 'ProjectActivityCommand',
    pathParams: [],
  },
  updateProjectActivity: {
    uri: 'Projects/updateprojectactivities',
    verb: 'POST',
    body: 'ProjectActivityCommand',
    pathParams: [],
  },
  deleteProjectActivityById: {
    uri: 'Projects/deleteprojectactivitybyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  fetchJobsByTenantId: {
    uri: 'Jobs/getalljobsbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  saveJob: {
    uri: 'Jobs/savejob',
    verb: 'POST',
    body: 'SaveProjectJobCommand',
    pathParams: [],
  },
  updateJob: {
    uri: 'Jobs/updatejob',
    verb: 'POST',
    body: 'SaveProjectJobCommand',
    pathParams: [],
  },
  fetchJobById: {
    uri: 'Jobs/getjobbyid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  deleteJobById: {
    uri: 'Jobs/deletejobbyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  saveJobPhase: {
    uri: 'Jobs/savejobphase',
    verb: 'POST',
    body: 'JobPhase',
    pathParams: [],
  },
  updateJobPhase: {
    uri: 'Jobs/updatejobphase',
    verb: 'POST',
    body: 'JobPhase',
    pathParams: [],
  },
  fetchAllJobPhaseByJobId: {
    uri: 'Jobs/getalljobphasebyjobid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  deleteJobPhaseById: {
    uri: 'Jobs/deletejobphasebyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  saveAttendancePunchInPunchOut: {
    uri: 'Attendance/attendancepunchinpunchout',
    verb: 'POST',
    pathParams: [],
    body: 'PunchInPunchOutCommand',
  },
  fetchPunchInStatusEmployeeid: {
    uri: 'Attendance/getpunchinstatus',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchAllJobsByTenantId: {
    uri: 'Jobs/getalljobsbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchJobPhaseByJobId: {
    uri: 'Jobs/getalljobphasebyjobid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  getIsFirstPunchInByDateAndEmployeeId: {
    uri: 'Attendance/getisfirstpunchinbydateandemployeeId',
    verb: 'GET',
    pathParams: [{ id: '', loggedTime: new Date() }],
  },
  fetchEmpAttendanceBySupervisorEmpId: {
    uri: 'Attendance/getattendancebysupervisoremployeeId',
    verb: 'GET',
    pathParams: [
      { supervisorId: '', fromdate: new Date(), todate: new Date(), mode: '' },
    ],
  },
  fetchAttendanceByEmpId: {
    uri: 'Attendance/getattendancebyemployeeid',
    verb: 'GET',
    pathParams: [{ id: '', fromDate: '', toDate: '' }],
  },

  fetchTimesheetsByEmpIdandStatus: {
    uri: 'TimeEntry/gettimesheetsbyemployeeidandstatus',
    verb: 'GET',
    pathParams: [{ employeeId: '', status: '' }],
  },
  fetchEmployeeBySupervisorEmpId: {
    uri: 'EmployeeSubOrdinate/getallemployeesbysupervisoremployeeid',
    verb: 'GET',
    pathParams: [{ supervisorEmployeeId: '' }],
  },
  changeTimesheetStatus: {
    uri: 'TimeEntry/changetimesheetstatus',
    verb: 'POST',
    pathParams: [],
    body: 'ChangeTimesheetStatusEntity',
  },
  fetchTimesheetsByTimesheetId: {
    uri: 'TimeEntry/gettimesheetentriesbytimesheetid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchTimesheetsByEmployeeIdAndStatusAndDates: {
    uri: 'TimeEntry/gettimesheetsbyemployeeidandstatusanddates',
    verb: 'GET',
    pathParams: [{ employeeId: '', status: '', fromDate: '', toDate: '' }],
  },
  fetchTimesheetsByEmployeeIdAndDates: {
    uri: 'TimeEntry/gettimesheetsbyemployeeidanddates',
    verb: 'GET',
    pathParams: [{ employeeId: '', fromDate: '', toDate: '' }],
  },

  fetchAdminUserByTenantId: {
    uri: 'User/getalladminusersbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchTimeEntries: {
    uri: 'TimeEntry/gettimeentries',
    verb: 'GET',
    pathParams: [{ employeeId: '', startDate: '', endDate: '' }],
  },
  saveTimeEntry: {
    uri: 'TimeEntry/savetimeentry',
    verb: 'POST',
    body: 'SaveTimeEntryCommand',
    pathParams: [],
  },
  deleteTimeEntry: {
    uri: 'TimeEntry/deletetimeentrybyid',
    verb: 'POST',
    body: 'GenericDeleteCommand',
    pathParams: [],
  },
  changeFirstAdmin: {
    uri: 'User/changefirstadmin',
    verb: 'POST',
    pathParams: [],
    body: 'ChangeFirstAdminCommand',
  },
  fetchPrefferedUOMoptions: {
    uri: 'TenantConfig/getpreferreduomoptionsbycategory',
    verb: 'GET',
    pathParams: [{ category: '' }],
  },
  saveTenantConfiguration: {
    uri: 'TenantConfig/savetenantconfig',
    verb: 'POST',
    body: 'SaveTenantConfigCommand',
    pathParams: [],
  },
  updateTenantConfiguration: {
    uri: 'TenantConfig/updatetenantconfig',
    verb: 'POST',
    body: 'SaveTenantConfigCommand',
    pathParams: [],
  },
  fetchTenantConfigByTenantId: {
    uri: 'TenantConfig/gettenantconfigbytenantid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },

  fetchDetailsbySearchQuery: {
    uri: 'search',
    verb: 'GET',
    pathParams: [{ query: '' }],
  },
  fetchAllConversations: {
    uri: 'knowledge_base/get_all_conversations',
    verb: 'GET',
    pathParams: [],
  },
  fetchCollections: {
    uri: 'knowledge_base/collections',
    verb: 'GET',
    pathParams: [],
  },
  fetchConversationById: {
    uri: 'knowledge_base/get_conversation?conversation_id=',
    verb: 'GET',
    pathParams: [{ conversation_id: '' }],
  },
  searchWithConversation: {
    uri: 'knowledge_base/search_with_conversation',
    verb: 'POST',
    pathParams: [
      {
        query: '',
        collection_ids: '',
        conversation_id: '',
      },
    ],
    body: 'SearchEntity',
  },

  deleteConversationByConversationId: {
    uri: 'knowledge_base/delete_conversation',
    verb: 'DELETE',
    pathParams: [
      {
        conversation_id: '',
      },
    ],
    body: 'GenericDeleteConversationCommand',
  },
  saveCollection: {
    uri: 'knowledge_base/create_collection',
    verb: 'POST',
    body: 'KnowledgeBaseCommand',
    pathParams: [],
  },
  fetchDocumentList: {
    uri: 'knowledge_base/list?collection_id=',
    verb: 'GET',
    pathParams: [{ collection_id: '' }],
  },
  uploadDocument: {
    uri: 'knowledge_base/upload',
    verb: 'POST',
    body: 'DocumentCommand',
    pathParams: [],
  },
  fetchJobPhaseById: {
    uri: 'Jobs/getjobphasebyid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  fetchAllActivityByPhaseId: {
    uri: 'Jobs/getallactivitiesbyphaseid',
    verb: 'GET',
    pathParams: [{ id: '' }],
  },
  deleteActivityById: {
    uri: 'Jobs/deleteactivitybyid',
    verb: 'POST',
    pathParams: [],
    body: 'GenericDeleteCommand',
  },
  saveActivity: {
    uri: 'Jobs/saveactivity',
    verb: 'POST',
    body: 'ProjectActivityCommand',
    pathParams: [],
  },
  updateActivity: {
    uri: 'Jobs/updateactivity',
    verb: 'POST',
    body: 'ProjectActivityCommand',
    pathParams: [],
  },
  fetchAllMruProjectByTenantIdAndEmployeeId: {
    uri: 'Projects/getallmruprojectsbytenantidandempid',
    verb: 'GET',
    pathParams: [{ tenantId: '', empId:'' }],
  },
  fetchAllMruJobByTenantIdAndEmployeeId: {
    uri: 'Jobs/getallmrujobsbytenantidandempid',
    verb: 'GET',
    pathParams: [{ tenantId: '', empId:'' }],
  },
  fetchAllMruJobByProjectIdAndEmployeeId: {
    uri: 'Jobs/getallmrujobsbyprojectidandempid',
    verb: 'GET',
    pathParams: [{ projectId: '', empId:'' }],
  },
  fetchAllMruPhaseByJobIdAndEmployeeId: {
    uri: 'Jobs/getallmrujobphasebyjobidandempid',
    verb: 'GET',
    pathParams: [{ jobId: '', empId:'' }],
  },
  fetchAllMruActivityByPhaseIdAndEmployeeId: {
    uri: 'Jobs/getallmruactivitiesbyphaseidandempid',
    verb: 'GET',
    pathParams: [{ phaseId: '', empId:'' }],
  },
};
