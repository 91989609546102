import { ersSettings } from '../configs/app.settings';

export const addSpacesToCamelCase = (input: string) => {
  return input.replace(/([A-Z])/g, ' $1').trim();
};

export const fetchPlaceHolderImageWithBfc = (text: string, size = `50x50`) => {
  const { backgroundColor, initialsColor } = randomColor();
  /** bc & fc */
  return `https://placehold.co/${size}/${backgroundColor}/${initialsColor}?font=Poppins&text=${text}`;
};

export const randomColor = () => {
  return ersSettings.colorCombinations[
    Math.floor(Math.random() * (ersSettings.colorCombinations.length - 1))
  ];
};

export const firstLetterFromString = (text: string, seperator = ' ') => {
  return text
    ? text
        .split(`${seperator}`)
        .map((s) => (s ? s[0].toUpperCase() : ''))
        .join('')
    : ``;
};
