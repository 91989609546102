import { BreadcumbMenuItem, MenuItem, SettingsMenuItem } from '../models';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

/**
 * application constants
 */
export const ersConstants = {
  SNACKBAR_DELAY_TIME: 5000,
  THEME_COOKIE_NAME: 'theme',
  ERS_JWT_TOKEN: 'ERS_JWT_TOKEN',
  REMEMBER_ME: 'REMEMBER_ME',
  AUTHENTICATION_PROVIDERS: {
    ERS: 'ERS_AP',
    GHL: 'GHL_AP',
    OAUTH2: 'OAUTH2_AP',
  },
  LOGGED_IN_USER_ID: 'LOGGED_IN_USER_ID',
  SELECTED_TENANT: 'SELECTED_TENANT',
  AUTHENTICATION_PROVIDERS_TYPE: 'AUTHENTICATION_PROVIDERS_TYPE',
  PASSWORD_PATTERN:
    '^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{8,}$',
  EMAIL_PATTERN: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
  DEFAULTS_ROUTE_PARMS: {
    ROUTE_BASE: 'YmFzZQ==',
    ROUTE_BASE_GUARD: 'ZnJvbWdhcnVk',
    TENANT_FOUND: 'dGVuYW50Zm91bmQ=',
    TENANT_NOT_FOUND: 'dGVuYW50bm90Zm91bmQ=',
    ROUTED_LOGIN: 'bG9naW4=',
  },
  GHL_USERID: 'GHL_USERID',
  GHL_TOKEN: 'ERS_GHL_TOKEN',
  OAuth_USERID: 'OAuth_USERID',
  OAuth_TOKEN: 'ERS_OAuth_TOKEN',
  ZIP_CODE_PATTERN: '^[0-9]+$',
  ROLE_BASED_AUTHENTICATION: 'ROLE_BASED_AUTHENTICATION',
  DEFAULT_PASSWORD: 'Password@1',
  PHONE_PATTERN_WITH_BRACKET: /^[\d+()\- ]+$/,
  PHONE_PATTERN: /^[0-9+]+$/,
  UUID_PATTERN: /\/[0-9A-Fa-f-]+/g,
  LONGITUDE_LATITUDE: /^((-?|\+?)?\d+(\.\d+)?)$/,

  ALPHANUMERIC: /^[a-zA-Z0-9]+$/,
  DECIMAL: /^[0-9]+(\.[0-9]+)?$/,

  DOCUMENT_SUPPORTED_EXTENSIONS: ['pdf', 'docx'],
  WEB_URL_PATTERN: '^(https?:\\/\\/)?([\\w\\d-]+\\.)+[\\w\\d]{2,}(\\/.*)?$',
};

export const ersMenuItemList = (): MenuItem[] => {
  return [
    {
      name: 'HR',
      label: 'HR Management',
      routerLink: '',
      icon: 'fa-solid fa-users-between-lines',
      level: 1,
      default: true,
      isToggle: true,
      children: [
        {
          name: '',
          label: 'Attendance',
          routerLink: '/ers/attendance',
          level: 2,
          default: false,
          icon: 'fa-solid fa-clock',
          children: [],
        },
        {
          name: '',
          label: 'Time Sheet',
          routerLink: '/ers/timesheet',
          level: 2,
          default: false,
          icon: 'fa-solid fa-people-roof',
          children: [],
        },
        {
          name: 'employee',
          label: 'Employee',
          routerLink: '/ers/employee',
          level: 2,
          default: false,
          icon: 'fa-solid fa-user',
          children: [],
        },
        {
          name: 'hr-configuration',
          label: 'Configuration',
          routerLink: '/ers/hr/configuration',
          level: 2,
          default: false,
          icon: 'fa-solid fa-money-bill-trend-up',
          children: [],
        },
      ],
    },
    {
      name: 'AI',
      label: 'Geralt AI',
      routerLink: '',
      icon: 'fa-solid fa-robot',
      level: 1,
      default: false,
      isToggle: true,
      children: [
        {
          name: '',
          label: 'Knowledge Base',
          routerLink: '/ers/knowledge-base',
          level: 2,
          default: false,
          icon: 'fa-solid fa-at',
          children: [],
        },
      ],
    },
  ];
};

export const ersSettingsMenuItemList = (): SettingsMenuItem[] => {
  return [
    {
      name: 'Organization',
      label: 'Organization',
      routerLink: '/ers/organization',
      level: 1,
      default: false,
      icon: 'fa-regular fa-building',
    },
    {
      name: 'access',
      label: 'Access',
      routerLink: '/ers/access',
      level: 1,
      default: false,
      icon: 'fa-solid fa-shield',
    },
    {
      name: 'usermanagement',
      label: 'Users',
      routerLink: '/ers/user',
      level: 1,
      default: false,
      icon: 'fa-solid fa-user-tie',
    },
  ];
};

export const ersHrConfigurationMenuItems = (): MenuItem[] => {
  return [
    {
      name: 'jobCategory',
      label: 'Job Category',
      routerLink: '/ers/hr/configuration/category',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'jobTitle',
      label: 'Job Title',
      routerLink: '/ers/hr/configuration/title',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'payGrade',
      label: 'Pay Grade',
      routerLink: '/ers/hr/configuration/pay-grade',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'workShift',
      label: 'Work Shift',
      routerLink: '/ers/hr/configuration/work-shift',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'projectDetails',
      label: 'Project',
      routerLink: '/ers/hr/configuration/project-details',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'project-job-list',
      label: 'Job',
      routerLink: '/ers/hr/configuration/jobs',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'projectActivity',
      label: 'Activity',
      routerLink: '/ers/hr/configuration/project-activity',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
  ];
};

export const ersEmpployeeMenuItems = (): MenuItem[] => {
  return [
    {
      name: 'myInfo',
      label: 'My Information',
      routerLink: '/ers/employee/me',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'employeeInfo',
      label: 'Employee Information',
      routerLink: '/ers/employee/info',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
  ];
};

export const ersTimesheetMenuItems = (): MenuItem[] => {
  return [
    {
      name: 'myTimesheet',
      label: 'My Timesheet',
      routerLink: '/ers/timesheet/me',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'employeeTimesheet',
      label: 'Employees Timesheet',
      routerLink: '/ers/timesheet/info',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
  ];
};

export const ersAttendanceMenuItems = (): MenuItem[] => {
  return [
    {
      name: 'punchInOut',
      label: 'Punch In/Out',
      routerLink: '/ers/attendance/punchinout',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'myAttendance',
      label: 'My Attendance',
      routerLink: '/ers/attendance/me',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'employeeAttendance',
      label: 'Employees Attendance',
      routerLink: '/ers/attendance/info',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
    {
      name: 'employeeAttendancedetails',
      label: 'Employee Attendance Details',
      routerLink: '/ers/attendance/details',
      level: 1,
      default: false,
      icon: 'fa-solid',
      isToggle: false,
      children: [],
    },
  ];
};

export const UserListBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },

  {
    label: 'Users',
    routerLink: ['/ers/user'],
  },
];

export const CreateUserBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },

  { label: 'Organisation', routerLink: ['/ers/organization'] },
  {
    label: 'User',
    routerLink: ['/ers/user'],
  },
  {
    label: 'Create User',
    routerLink: ['/ers/user/create'],
  },
];

export const ModifyUserBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },

  {
    label: 'User',
    routerLink: ['/ers/user'],
  },
  {
    label: 'Edit User',
    routerLink: ['/ers/user/details'],
  },
];

/** Organization Breadcrumbs */
export const OrganizationListBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },

  { label: 'Organization', routerLink: ['/ers/organization'] },
];

export const ModifyOrganizationBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },

  { label: 'Organization', routerLink: ['/ers/organization'] },
  {
    label: 'Edit Organization',
    routerLink: ['/ers/organization/details'],
  },
];

/** Roles Breadcrumbs */
export const RolesListBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },

  { label: 'Roles', routerLink: ['/ers/access/roles'] },
];

export const ModifyRoleBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },

  { label: 'Roles', routerLink: ['/ers/access/roles'] },
  {
    label: 'Edit Roles',
    routerLink: ['/ers/access/roles/details'],
  },
];

export const ModuleSubscriptionBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },

  { label: 'Organization', routerLink: ['/ers/organization'] },
  {
    label: 'Edit Organization',
    routerLink: ['/ers/organization/details'],
  },
  {
    label: 'Modules',
    routerLink: null,
  },
];

/** Settings Breadcrumbs */
export const SettingsListBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },
];

/** job Breadcrumbs */
export const JobTitleListBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Job Title', routerLink: ['/ers/title'] },
];

export const ModifyJobTitleBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Job Title', routerLink: ['/ers/hr/configuration/title'] },
  {
    label: 'Edit Job Title',
    routerLink: ['/ers/hr/configuration/title/details'],
  },
];

/** phase Breadcrumbs */
export const PhaseDetailsBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Job', routerLink: ['/ers/hr/configuration/jobs'] },
  {
    label: 'Phase Details',
    routerLink: ['/ers/hr/configuration/Phase/details'],
  },
];

export const JobCategoryListBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Job Category', routerLink: ['/ers/hr/configuration/category'] },
];

export const ModifyJobCategoryBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Job Category', routerLink: ['/ers/hr/configuration/category'] },
  {
    label: 'Job Category Details',
    routerLink: ['/ers/hr/configuration/create'],
  },
];

export const JobWorkShiftListBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Work Shift', routerLink: ['/ers/hr/configuration/work-shift'] },
];

export const ModifyJobWorkShiftyBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Work Shifts', routerLink: ['/ers/hr/configuration/work-shift'] },
  {
    label: 'Edit Work Shift Details',
    routerLink: ['/ers/work-shift/details/:id'],
  },
];

/** Pay Grade Breadcrumbs */
export const PayGradeListBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Pay Grade', routerLink: ['/ers/hr/configuration/pay-grade'] },
];

export const ModifyPayGradeBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Pay Grade', routerLink: ['/ers/hr/configuration/pay-grade'] },
  {
    label: 'Edit Pay Grade',
    routerLink: ['/ers/hr/configuration/pay-grade/details'],
  },
];

/** Project Details Breadcrumbs */
export const ProjectDetailsListBreadcrumb: BreadcumbMenuItem[] = [
  {
    label: 'Project',
    routerLink: ['/ers/hr/configuration/project-details'],
  },
];

/** Project Activities Details Breadcrumbs */
export const ProjectActivityListBreadcrumb: BreadcumbMenuItem[] = [
  {
    label: 'Activity',
    routerLink: ['/ers/hr/configuration/project-activity'],
  },
];

export const timePickerTheme: NgxMaterialTimepickerTheme = {
  container: {
    bodyBackgroundColor: '#ffffff',
    buttonColor: '#000',
  },
  dial: {
    dialBackgroundColor: '#343a40',
  },
  clockFace: {
    clockFaceBackgroundColor: '#e4e4e4',
    clockHandColor: '#3f51b5',
    clockFaceTimeInactiveColor: '#000000',
  },
};

/** Employee Breadcrumbs */
export const EmployeeListBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'HR Management', routerLink: [''] },

  { label: 'Employee Information', routerLink: ['/ers/employee'] },
];

/** Project Breadcrumbs */

export const ProjectJobListBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },
  { label: 'Job', routerLink: ['/ers/hr/configuration/jobs'] },
];
export const ModifyProjectJobBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Job', routerLink: ['/ers/hr/configuration/jobs'] },
  {
    label: 'Edit Job',
    routerLink: ['/ers/hr/configuration/job/details'],
  },
];

export const InviteUserBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },
  { label: 'Organisation', routerLink: ['/ers/organization'] },
  {
    label: 'User',
    routerLink: ['/ers/user'],
  },
  {
    label: 'Invite User',
    routerLink: ['/ers/user/invite'],
  },
];
export const SubscribeAccessBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },

  { label: 'Organization', routerLink: ['/ers/organization'] },
  {
    label: 'Edit Organization',
    routerLink: ['/ers/organization/details/:id'],
  },
  {
    label: 'Modules',
    routerLink: ['/ers/organization/details/access/:id'],
  },
  {
    label: 'Subscription',
    routerLink: ['/ers/organization/details/subscription/:id/:tenantId'],
  },
];

/** Timesheet Rejection Reasons */
export const ersTimesheetRejectionReasonsList = [
  'Inaccurate time entries',
  'Missing job ID or job phase',
  'Incorrect geolocation data',
  'Overlapping time entries',
  'Unauthorized overtime',
  'Incomplete timesheet',
  'Other',
];

/** Organization Configuration Breadcrumbs */
export const OrganizationConfigrationBreadcrumb: BreadcumbMenuItem[] = [
  { label: 'Settings', routerLink: ['/ers/settings'] },

  { label: 'Organization', routerLink: ['/ers/organization'] },
  {
    label: 'Edit Organization',
    routerLink: ['/ers/organization/details'],
  },
  {
    label: 'Configuration',
    routerLink: null,
  },
];
