export enum RoleEnum {
  SuperAdmin = 'super admin',
  Admin = 'admin',
  Ess = 'ess',
}

export enum GenericCollectionType {
  AccountType = 'ACCOUNT_TYPE',
  TimesheetDuration = 'TIMESHEET_DURATION',
  REPORTINGMETHOD = 'REPORTING_METHOD',
  DependentRelationship = 'DEPND_REL',
  EmgContactRelationship = 'EMER_CONT_REL',
  EmployeeFileCategory = 'EMP_FILES_CAT',
  GovtIdType = 'GOVT_ID_TYPE',
  PayFrequency = 'PAY_FREQUENCY',
  ReportType = 'REPORTING_TYPE',
  SsoProvider = 'SSO_PROVIDER',
  SubscriptionDuration = 'SUBSCRIPTION_DURATION',
  TenantPrefeKey = 'TENANT_PREFERENCE_KEY',
  TenantPrefeValue = 'TENANT_PREFERENCE_VALUE',
  UserPrefeKey = 'USER_PREFERENCE_KEY',
  UserPrefeValue = 'USER_PREFERENCE_VALUE',
  TenantLocation = 'TENANT_LOCATION_TYPE',
  TimesheetStatus = 'TIMESHEET_STATUS',
}

export enum ReportTo {
  addSubordinate = 'Assign Subordinate',
  addSupervisor = 'Assign Supervisor',
}

export enum SecondaryRouteKeys {
  HrConfig = 'hr',
  Employee = 'employee',
  Timesheet = 'timesheet',
  Attendance = 'attendance',
}

export enum TimesheetStatusKeys {
  NotSubmitted = 'Not Submitted',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
